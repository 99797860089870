import React from "react";
import PropTypes from 'prop-types';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const PuzzleSelector = ({ open, onChange, availablePuzzles }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function availablePuzzlesFound() {
    return availablePuzzles && availablePuzzles.length > 0;
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Choose a Puzzle</DialogTitle>

      {!availablePuzzlesFound() &&
      <Redirect to={"/creator"}/>
      }

      <div className={classes.root}>
        {availablePuzzles.map((puzzle) => (
          <Accordion key={puzzle.id} expanded={expanded === puzzle.id} onChange={handleChange(puzzle.id)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls={puzzle.id}
              id={puzzle.id}>
              <Typography className={classes.secondaryHeading}>{puzzle.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Typography>
                  {puzzle.description}
                </Typography>
                <Button variant="contained" color="primary" size="small" onClick={() => onChange(puzzle.id)}>
                  Try
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

    </Dialog>
  );
}

PuzzleSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  availablePuzzles: PropTypes.array.isRequired,
};

export default PuzzleSelector;
