import React from "react";
import PropTypes from 'prop-types';
import { GivenSquare, Square } from "./Square";

export const mode = {
  EDIT: "edit",
  SOLVE: "solve",
};

const Board = ({ body, onClick, onGivenClick, mode }) => {

  function renderSquare(row, col) {
    const key = row + ' ' + col;

    let className = "sudoku-grid-item";

    if (col === 2 || col === 5) {
      className += " sudoku-square-right";
    }

    if (row === 2 || row === 5) {
      className += " sudoku-square-bottom";
    }

    if (mode === 'edit') {
      return <div key={key} className={className} onClick={() => onClick(row, col)}>
        <GivenSquare
          value={body.initialPuzzle[row][col]}
          backgroundColor={body.backgroundColors[row][col]}
          backgroundImage= {body.backgroundImages[row][col]}
        />
      </div>;
    }

    if (body.initialPuzzle[row][col] === null) {
    return <div key={key} className={className} onClick={() => onClick(row, col)}>
      <Square
        value={body.cells[row][col]}
        snyder={body.snyders[row][col]}
        backgroundColor={body.backgroundColors[row][col]}
        backgroundImage= {body.backgroundImages[row][col]}
      />
    </div>;
    } else {
      return <div key={key} className={className} onClick={() => onGivenClick(row, col)}>
        <GivenSquare
          value={body.initialPuzzle[row][col]}
          backgroundColor = {body.backgroundColors[row][col]}
          backgroundImage= {body.backgroundImages[row][col]}
        />
      </div>;
    }
  }

  const squares = [];

  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      squares.push(renderSquare(row, col));
    }
  }

  return (
    <div className="sudoku-grid-container">
      {squares}
    </div>
  );
};

Board.propTypes = {
  body: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onGivenClick: PropTypes.func.isRequired,
  mode: PropTypes.string
};

export default Board;
