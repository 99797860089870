import React, { useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { cloneDeep } from "lodash";
import * as api from "./Api";
import "./Sudoku.css";
import Board, { mode } from "./Board";
import Layout from "../Layout";
import KeyBoardForCreating from "./KeyBoardForCreating";
import OkCancelDialog from "./OkCancelDialog";
import TextField from "@material-ui/core/TextField";
import { getNoImage } from "./BackgroundImagesAndColours";
import { buttons } from "./KeyBoard";

const SudokuCreator = () => {

  const [body, setBody] = useState(api.anEmptyBody()); // todo rename as puzzle
  const [label, setLabel] = useState("");
  const [description, setDescription] = useState("");

  const [selectedNumber, setSelectedNumber] = useState();
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(getNoImage());
  const [okCancelDialogOpen, setOkCancelDialogOpen] = useState(false);

  function putToAPI(data) {
    api.putPuzzle(data);
  }

  function onSaveClick() {
    body.id = "" + 9900000 + Math.floor(Math.random() * 10000);  // todo server need to create id
    body.label = label
    body.description = description

    putToAPI(body)
    handleResetClick();
  }

  function handleCellClick(row, col) {
    setBody((previousBody) => {
      let updatedBody = cloneDeep(previousBody);

      if (selectedNumber === buttons.CLEAR_CELL) {
        // delete was pressed
        updatedBody.initialPuzzle[row][col] = null;
        updatedBody.backgroundImages[row][col] = getNoImage();

      } else if (selectedBackgroundImage !== getNoImage()) {
        if (updatedBody.backgroundImages[row][col] === selectedBackgroundImage) {
          updatedBody.backgroundImages[row][col] = getNoImage();
        } else {
          updatedBody.backgroundImages[row][col] = selectedBackgroundImage;
        }
      } else if (updatedBody.initialPuzzle[row][col] === selectedNumber) {
        updatedBody.initialPuzzle[row][col] = null;
      } else {
        updatedBody.initialPuzzle[row][col] = selectedNumber;
      }
      return updatedBody;
    });
  }

  function handleImageClick(value) {
    setSelectedBackgroundImage(value)
  }

  function handleResetClick() {
    setBody(api.anEmptyBody())
    setLabel("")
    setDescription("")
  }

  return (
    <Layout hideFooter={true}>
      <Container maxWidth="sm">
        <Grid container direction="column" justify="center" alignItems="center">

          <TextField
            placeholder="Label"
            variant="outlined"
            style={{ marginTop: 8 }}
            fullWidth
            multiline={true}
            inputProps={{ maxLength: 45 }}
            value={label}
            onChange={(event) => {
              setLabel(event.target.value)
            }}
          />

          <TextField
            placeholder="Description"
            variant="outlined"
            style={{ marginTop: 8, marginBottom: 8 }}
            fullWidth
            multiline={true}
            inputProps={{ maxLength: 100 }}
            value={description}
            onChange={(event) => {
              setDescription(event.target.value)
            }}
          />

          <Board
            body={body}
            onClick={(row, col) => handleCellClick(row, col)}
            onGivenClick={(row, col) => console.log(row + " " + col)} // todo
            mode={mode.EDIT}
          />

          <KeyBoardForCreating
            onValueClick={(value) => setSelectedNumber(value)}
            onResetClick={() => setOkCancelDialogOpen(true)}
            onSaveClick={() => onSaveClick()}
            onImageClick={(image) => handleImageClick(image)}
          />

          <OkCancelDialog
            open={okCancelDialogOpen}
            onOk={() => {
              setOkCancelDialogOpen(false);
              handleResetClick()
            }}
            onCancel={() => setOkCancelDialogOpen(false)}
            title={"You sure ?"}
            description={"There is no going back"}
          />
        </Grid>
      </Container>
    </Layout>
  );
};

export default withAuthenticator(SudokuCreator);
