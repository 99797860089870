import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import ImageSelector from "./ImageSelector";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    margin: theme.spacing(1),
  },
  keyboard: {
    paddingTop: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const buttons = {
  CLEAR_CELL: "x"
};

const KeyBoardForCreating = ({ onValueClick, onResetClick, onSaveClick, onImageClick }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState();
  const [imageSelectorOpen, setImageSelectorOpen] = useState(false);

  const aDataValue = (id) => {
    return `sudokuKeyBoardKey-${id}`
  }

  // todo why state here ?
  const handleSelectedValue = (event, newSelectedValue) => {
    onImageClick(null)

    setSelectedValue(newSelectedValue);
    onValueClick(newSelectedValue)
  };

  // todo why state here ?
  const handleImageIdChange = (imageId) => {
    onImageClick(imageId)

    setSelectedValue(null)
    onValueClick(selectedValue)
    setImageSelectorOpen(false)
  };

  function aPrimaryButton(label, onClick) {
    return (
      <Button
        className={classes.primaryButton}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => onClick()}>
        {label}
      </Button>
    );
  }

  const aToggleNumberButton = (i) => {
    return (
      <ToggleButton
        key={i}
        data-value={aDataValue(i)}
        id="sudokuKeyBoardKey"
        value={i}>
        {i}
      </ToggleButton>)
  }

  const aToggleDeleteButton = () => {
    let i = buttons.CLEAR_CELL;
    return (
      <ToggleButton
        key={i}
        data-value={aDataValue(i)}
        id="sudokuKeyBoardKey"
        value={i}>
        <DeleteForeverOutlined style={{ fontSize: 20 }}/>
      </ToggleButton>)
  }

  const aSelector = () => {
    return (
      <Button
        className={classes.primaryButton}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setImageSelectorOpen(true)}
      >
        Select Image
      </Button>
    )
  }

  const toggleButtons = [];

  for (let i = 1; i <= 9; i++) {
    toggleButtons.push(aToggleNumberButton(i));
  }

  toggleButtons.push(aToggleDeleteButton());

  return (
    <div className={classes.keyboard}>
      <Grid container justify="center">
        <ToggleButtonGroup
          value={selectedValue}
          exclusive
          onChange={handleSelectedValue}
          aria-label="text SelectedValue">
          {toggleButtons}
        </ToggleButtonGroup>
      </Grid>

      <Grid container justify="center">
        {aSelector()}
        {aPrimaryButton("Reset", onResetClick)}
        {aPrimaryButton("Save", onSaveClick)}
      </Grid>

      <ImageSelector
        open={imageSelectorOpen}
        onChange={(value) => handleImageIdChange(value)}
      />
    </div>
  );
};

KeyBoardForCreating.propTypes = {
  onValueClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired
};

export default KeyBoardForCreating;
