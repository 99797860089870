import { cloneDeep } from "lodash";

export function anEmptyPuzzle() {
  let n = null;

  return [
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
  ];
}

export function isSolved(body) {
  let badBoxes = [];
  let badRows = [];
  let badCols = [];

  let cells = merge(body.cells, body.initialPuzzle)

  for (let blockRow = 0; blockRow <= 2; blockRow++) {
    for (let blockCol = 0; blockCol <= 2; blockCol++) {
      let data = getBlock(blockRow, blockCol, cells);
      if (!isComplete(data)) {
        badBoxes.push((blockRow * 3) + blockCol + 1);
      }
    }
  }

  for (let row = 0; row <= 8; row++) {
    let data = getRow(row, cells);
    if (!isComplete(data)) {
      badRows.push(row + 1);
    }
  }

  for (let col = 0; col <= 8; col++) {
    let data = getColumn(col, cells);
    if (!isComplete(data)) {
      badCols.push(col + 1);
    }
  }

  return {
    boxes: badBoxes,
    rows: badRows,
    cols: badCols,
  }
}

export function isComplete(array) {
  for (let i = 1; i <= 9; i++) {
    if (countOccurrences(i, array) !== 1) {
      return false;
    }
  }

  return true;
}

export function countOccurrences(num, array) {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i] === num) count++;
  }

  return count;
}

export function getBlock(blockRow, blockColumn, cells) {
  let rowOffset = blockRow * 3;
  let colOffset = blockColumn * 3;

  return [
    cells[rowOffset + 0][colOffset + 0],
    cells[rowOffset + 0][colOffset + 1],
    cells[rowOffset + 0][colOffset + 2],
    cells[rowOffset + 1][colOffset + 0],
    cells[rowOffset + 1][colOffset + 1],
    cells[rowOffset + 1][colOffset + 2],
    cells[rowOffset + 2][colOffset + 0],
    cells[rowOffset + 2][colOffset + 1],
    cells[rowOffset + 2][colOffset + 2],
  ];
}

export function getRow(row, cells) {
  return cells[row];
}

export function getColumn(col, cells) {
  return [
    cells[0][col],
    cells[1][col],
    cells[2][col],
    cells[3][col],
    cells[4][col],
    cells[5][col],
    cells[6][col],
    cells[7][col],
    cells[8][col],
  ];
}

export function merge(cell, initialPuzzle) {
  let data = cloneDeep(initialPuzzle);
  let cellClone = cloneDeep(cell);

  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (cellClone[row][col]) {
        data[row][col] = cellClone[row][col];
      }
    }
  }

  return data;
}

