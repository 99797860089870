import React from 'react';
import './App.css';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Home from './components/home/Home';
import Data from './components/sudoku/Data';
import About from './components/about/About';
import NotFound from './components/not-found/NotFound';
import SudokuSolver from './components/sudoku/SudokuSolver';
import SudokuCreator from './components/sudoku/SudokuCreator';
import CssBaseline from '@material-ui/core/CssBaseline';

const App = () => {

    // https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually
    return (
      <React.Fragment>
        <CssBaseline />
        <HashRouter>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/about" component={About}/>
                <Route path="/sudoku" component={SudokuSolver}/>
                <Route path="/creator" component={SudokuCreator}/>
                <Route path="/data" component={Data}/>
                <Route component={NotFound}/>
            </Switch>
        </HashRouter>
      </React.Fragment>
    )
};

export default App;
