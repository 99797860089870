import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SportsESportsIcon from '@material-ui/icons/SportsEsports';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import StorageIcon from '@material-ui/icons/Storage';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import InfoIcon from '@material-ui/icons/Info';
import { Link, useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Auth } from 'aws-amplify';
import MenuIcon from '@material-ui/icons/Menu';
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  homeLink: {
    color: "white",
    textDecoration: "none"
  }
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const [userName, setUserName] = useState();
  const [drawIsOpen, setDrawIsOpen] = useState(false);

  async function setCognitoDetails() {
    const { username } = await Auth.currentAuthenticatedUser();
    setUserName(username);
  }

  useEffect(() => {
    setCognitoDetails().catch(() => setUserName(null));
  }, []);

  function signOut() {
    Auth.signOut().then(() => history.push("/"));
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawIsOpen(open);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Button onClick={toggleDrawer(true)}>
            <MenuIcon/>
          </Button>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            <Link className={classes.homeLink} to="/">
              Sudoku by Nytram
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'left'}
        open={drawIsOpen}
        onClose={toggleDrawer(false)}
      >
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem key={1} component={Link} to="/">
              <ListItemIcon> <HomeIcon/> </ListItemIcon>
              <ListItemText primary="Home"/>
            </ListItem>

            <Divider/>

            <ListItem key={2} component={Link} to="/sudoku">
              <ListItemIcon> <SportsESportsIcon/> </ListItemIcon>
              <ListItemText primary="Play"/>
            </ListItem>

            <ListItem key={3} component={Link} to="/creator">
              <ListItemIcon> <CreateIcon/> </ListItemIcon>
              <ListItemText primary="Create"/>
            </ListItem>

            <ListItem key={4} component={Link} to="/data">
              <ListItemIcon> <StorageIcon/> </ListItemIcon>
              <ListItemText primary="Data"/>
            </ListItem>

            <Divider/>

            <ListItem key={6} component={Link} to="/about">
              <ListItemIcon> <InfoIcon/> </ListItemIcon>
              <ListItemText primary="About"/>
            </ListItem>

            {
              (userName && userName !== 'guest') &&

              <ListItem key={6.1} component="a" href="https://develop.d36dngpg2h1kmk.amplifyapp.com">
                <ListItemIcon> <DeveloperModeIcon/> </ListItemIcon>
                <ListItemText primary="Develop"/>
              </ListItem>
            }

            {
              userName &&
              <ListItem key={7} component={Link} to="/" onClick={signOut}>
                <ListItemIcon> <AccountBoxIcon/> </ListItemIcon>
                <ListItemText primary="Sign Out"/>
              </ListItem>
            }
          </List>
        </div>
      </Drawer>
    </div>
  );
}
