import React, { useEffect, useState } from "react";
import Container from '@material-ui/core/Container';
import Layout from '../Layout';
import * as api from "./Api";
import { allPuzzles } from "../../assets/data_export";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useCookies } from "react-cookie";
import { Auth } from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  introduction: {
    paddingTop: theme.spacing(4),
  }
}));

function Data() {
  const classes = useStyles();

  const [message, setMessage] = useState([]);
  const [offlinePuzzles, setOfflinePuzzles] = useState([]);
  const [availableCount, setAvailableCount] = useState(0);
  const [cookies, setCookies, removeCookies] = useCookies(['puzzleId']); // todo move to cookies.js
  const [userName, setUserName] = useState();

  useEffect(() => {
    setCognitoDetails()
    setMessage([])
    setOfflinePuzzles(api.localStoragePuzzle.getOffline());
    setAvailablePuzzleCount();
  }, []);

  async function setCognitoDetails() {
    const { username } = await Auth.currentAuthenticatedUser();
    setUserName(username);
  }

  async function setAvailablePuzzleCount() {
    let available = api.localStoragePuzzle.getAvailable();
    if (available && available.length > 0) {
      setAvailableCount(available.length);
    }
  }

  async function removeAllLocal() {
    api.localStoragePuzzle.removeAllOffline();
    setOfflinePuzzles(api.localStoragePuzzle.getOffline())
    setMessage([]);
  }

  function removeCookieHandler() {
    setCookies("puzzleId", ""); // todo not needed
    removeCookies('puzzleId');
  }

  function removeAvailableHandler() {
    api.localStoragePuzzle.removeAvailable()
    setAvailableCount(0)
  }

  async function resetAllProgress() {
    setMessage([]);

    allPuzzles().forEach((puzzle) => {
      api.putPuzzle(puzzle)

      setMessage((previous) => {
        previous.push({ id: puzzle.id, label: puzzle.label });
        return previous;
      });
    });

    await api.getAvailable()
      .then(available => {
          console.log({ available })
          api.getPuzzle(available[0].id);
        }
      );

    setOfflinePuzzles(api.localStoragePuzzle.getOffline())
    setAvailableCount(api.localStoragePuzzle.getAvailable().length)
  }

  return (

    <Layout>
      <Typography className={classes.introduction} component="h4" variant="h4" align="center" color="textPrimary"
                  gutterBottom>
        Data for {userName}
      </Typography>

      <Container className={classes.cardGrid} maxWidth="md">

        <Grid container spacing={4}>

          <Grid item key={1} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Online Puzzles
                </Typography>

                <Typography>
                  You can reset all your puzzles if you think they are corrupted
                </Typography>

                <List>
                  {
                    message.map((p) => (
                      <ListItem key={p.id}>{p.label}</ListItem>
                    ))
                  }
                </List>

              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => resetAllProgress()}>
                  Reset
                </Button>
              </CardActions>
            </Card>
          </Grid>


          {availableCount > 0 &&
          <Grid item key={2} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Cached Available Puzzles
                </Typography>
                {availableCount === 1 &&
                <Typography>There is 1 cached puzzle</Typography>
                }

                {availableCount > 1 &&
                <Typography>There are {availableCount} cached puzzles</Typography>
                }
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => removeAvailableHandler()}>
                  Remove
                </Button>
              </CardActions>
            </Card>
          </Grid>
          }

          {offlinePuzzles.length > 0 &&
          <Grid item key={3} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Locally Updated Puzzles
                </Typography>

                {offlinePuzzles.length > 0 &&
                <Typography>Please go back online and complete the following</Typography>
                }

                <List>
                  {
                    offlinePuzzles.map((p) => (
                      <ListItem key={p.id}>{p.label}</ListItem>
                    ))
                  }
                </List>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => removeAllLocal()}>
                  Remove
                </Button>
              </CardActions>
            </Card>
          </Grid>
          }

          {(cookies !== null && cookies.puzzleId) &&
          <Grid item key={4} xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  Current Puzzle Cookie
                </Typography>
                <Typography>

                  <span>{cookies.puzzleId}</span>
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => removeCookieHandler()}>
                  Remove
                </Button>
              </CardActions>
            </Card>
          </Grid>
          }
        </Grid>

      </Container>
    </Layout>
  );
}

export default withAuthenticator(Data);
