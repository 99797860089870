import React from "react";
import Layout from "../Layout";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <Layout>
      <Container maxWidth="sm">
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            About
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
          <span role="img" aria-labelledby="guests">
            You can logon as <b>guest</b> with password <b>guestguest</b>.
            But, given this is a 'proof of concept' app you are highly likely to lose solve progress.
          </span>
          </Typography>

          <Button component={Link} to={'/sudoku'}
                  variant="contained"
                  color="primary"
                  size="small">
            Play
          </Button>

          <Typography variant="h5" align="center" color="textSecondary" paragraph style={{ marginTop: 8 }}>

          <span role="img" aria-labelledby="guests">
            If you see any weirdness then please reset your data.
          </span>
          </Typography>

          <Button component={Link} to={'/data'}
                  variant="contained"
                  color="primary"
                  size="small">
            Reset
          </Button>
        </Grid>
      </Container>
    </Layout>
  );
}
