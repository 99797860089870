import { API } from "aws-amplify";
import { anEmptyPuzzle } from "./Puzzle";
import { anEmptySnyders } from "./SnyderNotation";

export function aBody(id, initialPuzzle, cells, snyders, label, description, backgroundColors, backgroundImages) {
  return {
    id: id,
    initialPuzzle: initialPuzzle,
    cells: cells,
    snyders: snyders,
    backgroundColors: backgroundColors,
    backgroundImages: backgroundImages,
    label: label,
    description: description
  };
}

export function anEmptyBody() {
  return {
    id: -1,
    initialPuzzle: anEmptyPuzzle(),
    cells: anEmptyPuzzle(),
    snyders: anEmptySnyders(),
    backgroundColors: anEmptyPuzzle(),
    backgroundImages: anEmptyPuzzle(),
    label: "",
    description: ""
  }
}

export function putPuzzle({ id, initialPuzzle, cells, snyders, label, description, backgroundColors, backgroundImages }) {
  API.put('suduko', '/puzzles', {
    body: {
      id: id,
      initialPuzzle: initialPuzzle,
      cells: cells,
      snyders: snyders,
      backgroundColors: backgroundColors,
      backgroundImages: backgroundImages,
      label: label,
      description: description,
    }
  }).then(() => {
    try {
      localStoragePuzzle.removePuzzle(id);
    } catch (e) {
      console.log({ e })
    }
  }).catch(() => {
    localStoragePuzzle.setPuzzle(aBody(id, initialPuzzle, cells, snyders, label, description,  backgroundColors, backgroundImages));
  });
}

export async function getPuzzle(id) {
  try {
    try {
      let localPuzzle = localStoragePuzzle.getPuzzle(id);

      if (localPuzzle) {
        return localPuzzle;
      }
    } catch (e) {
      console.log({ e })
    }

    return await API.get('suduko', '/puzzles/object/' + id);
  } catch (err) {

    let localPuzzle = localStoragePuzzle.getPuzzle(id);

    if (localPuzzle) {
      return localPuzzle;
    }

    let available = localStoragePuzzle.getAvailable();

    if (available) {
      return available.find(puzzle => puzzle.id === id);
    }
    // todo if not available
  }
}

export function getAvailable() {
  return API.get('suduko', '/puzzles/available')
    .then((available) => {
      localStoragePuzzle.setAvailable(available);

      return Promise.resolve(localStoragePuzzle.mergeWithNewOffline(available));
    })
    .catch(() => {
        let available = localStoragePuzzle.getAvailable();

        return Promise.resolve(localStoragePuzzle.mergeWithNewOffline(available))
      }
    )
}

export const localStoragePuzzle = {
  setAvailable: function (available) {
    localStorage.setItem("available", JSON.stringify(available));
  },
  getAvailable: function () {
    return JSON.parse(localStorage.getItem("available"))
  },
  setOffline: function (offline) {
    localStorage.setItem("offline", JSON.stringify(offline));
  },
  getOffline: function () {
    let offline = JSON.parse(localStorage.getItem("offline"))

    if (!offline) {
      offline = [];
    }

    return offline;
  },
  setPuzzle: function (puzzle) {
    let offline = localStoragePuzzle.getOffline();

    offline = offline.filter(p => p.id !== puzzle.id); // todo just update it
    offline.push(puzzle);
    localStoragePuzzle.setOffline(offline);
  },
  getPuzzle: function (id) {
    let offline = localStoragePuzzle.getOffline();

    return offline.find(puzzle => puzzle.id === id);
  },
  removePuzzle: function (id) {
    let offline = localStoragePuzzle.getOffline();

    offline = offline.filter(puzzle => puzzle.id !== id);

    if (offline.length > 0) {
      localStoragePuzzle.setOffline(offline);
    } else {
      localStoragePuzzle.removeAllOffline();
    }
  },
  removeAllOffline: function () {
    localStorage.removeItem("offline")
  },
  removeAvailable: function () {
    localStorage.removeItem("available")
  },
  mergeWithNewOffline: function (available) {
    let created = localStoragePuzzle.getOffline()
      .filter(puzzle => !puzzle.id.includes('@')) // todo need to sort out where id is created

    return created.concat(available)
  },
};
