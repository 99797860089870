import React from "react";
import PropTypes from 'prop-types';
import { validateSnyder } from "./SnyderNotation";
import { buildStyle } from "./BackgroundImagesAndColours";

export const GivenSquare = ({ value, backgroundColor, backgroundImage }) => {
  return (
    <div className="sudoku-square sudoku-given-square" style={buildStyle(backgroundColor, backgroundImage)}>
      {value}
    </div>
  )
};

export const Square = ({ value, snyder, backgroundColor, backgroundImage }) => {

  // todo can't auto work this out ?
  const locations = [
    "sudoku-square-grid-item-tl",
    "sudoku-square-grid-item-tr",
    "sudoku-square-grid-item-bl",
    "sudoku-square-grid-item-br",
  ];

  if (value || !validateSnyder(snyder)) {
    return (
      <div className="sudoku-square" style={buildStyle(backgroundColor, backgroundImage)}>
        {value}
      </div>
    );
  }

  let boxValues = snyder.box.map((num, index) => {
    let className = `sudoku-square-grid-item ${locations[index]}`;
    return (
      <div key={num} className={className}>
        {num}
      </div>
    )
  });

  const mid = snyder.cell.join('');
  let cellValues;

  if (mid) {
    cellValues = (
      <div className="sudoku-square-grid-item sudoku-square-grid-item-mid">
        {mid}
      </div>
    )
  }

  return (
    <div className="sudoku-square sudoku-square-grid-container" style={buildStyle(backgroundColor, backgroundImage)}>
      {boxValues}
      {cellValues}
    </div>
  );
};

GivenSquare.propTypes = {
  value: PropTypes.number,
  backgroundColor: PropTypes.number,
  backgroundImage: PropTypes.number,
};

Square.propTypes = {
  value: PropTypes.number,
  backgroundColor: PropTypes.number,
  backgroundImage: PropTypes.number,
  snyder: PropTypes.shape({
    box: PropTypes.arrayOf(PropTypes.number).isRequired,
    cell: PropTypes.arrayOf(PropTypes.number).isRequired
  }),
};

export default Square;
