import BulbN from '../../assets/thermo_bulb_n.svg';
import BulbNE from '../../assets/thermo_bulb_ne.svg';
import BulbE from '../../assets/thermo_bulb_e.svg';
import BulbSE from '../../assets/thermo_bulb_se.svg';
import BulbS from '../../assets/thermo_bulb_s.svg';
import BulbSW from '../../assets/thermo_bulb_sw.svg';
import BulbW from '../../assets/thermo_bulb_w.svg';
import BulbNW from '../../assets/thermo_bulb_nw.svg';

import EndN from '../../assets/thermo_end_n.svg';
import EndNE from '../../assets/thermo_end_ne.svg';
import EndE from '../../assets/thermo_end_e.svg';
import EndSE from '../../assets/thermo_end_se.svg';
import EndS from '../../assets/thermo_end_s.svg';
import EndSW from '../../assets/thermo_end_sw.svg';
import EndW from '../../assets/thermo_end_w.svg';
import EndNW from '../../assets/thermo_end_nw.svg';

import ElbowN from '../../assets/thermo_elbow_n.svg';
import ElbowNE from '../../assets/thermo_elbow_ne.svg';
import ElbowE from '../../assets/thermo_elbow_e.svg';
import ElbowSE from '../../assets/thermo_elbow_se.svg';
import ElbowS from '../../assets/thermo_elbow_s.svg';
import ElbowSW from '../../assets/thermo_elbow_sw.svg';
import ElbowW from '../../assets/thermo_elbow_w.svg';
import ElbowNW from '../../assets/thermo_elbow_nw.svg';

import PathE from '../../assets/thermo_path_e.svg';
import PathN from '../../assets/thermo_path_n.svg';
import PathSE from '../../assets/thermo_path_se.svg';
import PathSW from '../../assets/thermo_path_sw.svg';

export const images = [
  BulbN, BulbNE, BulbE, BulbSE, BulbS, BulbSW, BulbW, BulbNW,
  EndN, EndNE, EndE, EndSE, EndS, EndSW, EndW, EndNW,
  ElbowN, ElbowNE, ElbowE, ElbowSE, ElbowS, ElbowSW, ElbowW, ElbowNW,
  PathE, PathN, PathSE, PathSW
];

// https://www.materialui.co/htmlcolors/orange
const colours = [
  "rgba(205,92,92,0.6)", // "Indian Red"
  "rgba(255,165,0,0.6)", // "Orange",
  "rgba(144,238,144,0.6)", // "LightGreen",
  "rgba(173,216,230,0.6)", // "LightBlue",
  "rgba(106,90,205,0.6)", // "SlateBlue",
  "rgba(238,130,238,0.6)", // "Violet",
  "rgba(128,128,0,0.6)", // "Olive",
  "rgba(211,211,211,0.6)", // "LightGray",
  "rgba(119,136,153,0.6)", // "LightSlateGray"
];

export function getNoColour() {
  return null;
}

export function getFirstColour() {
  return 0;
}

export function getColour(backgroundColor) {
  return colours[backgroundColor];
}

export function rotateColour(selectedBackgroundColor) {
  if (selectedBackgroundColor === colours.length - 1) {
    return 0;
  } else {
    return selectedBackgroundColor + 1;
  }
}

export function getNoImage() {
  return null;
}

export function buildStyle(backgroundColor, backgroundImage) {
  let style = {};

  let colour = getColour(backgroundColor);

  if (backgroundColor != null) {
    style['backgroundColor'] = colour
  }

  if (backgroundImage != null) {
    let img = images[backgroundImage]

    style['backgroundPosition'] = 'center'
    style['backgroundSize'] = 'cover'
    style['backgroundRepeat'] = 'no-repeat'

    if (backgroundColor == null) {
      style['backgroundImage'] = `url("${img}")`
    } else {
      style['backgroundImage'] = `linear-gradient(to right, ${colour}, ${colour} ), url("${img}")`
    }
  }

  return style;
}
