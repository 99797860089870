import { cloneDeep, includes, union } from "lodash";

const maxBox = 4;
const maxCell = 6;

export const snyder_cell_mode = {
  SOLUTION: "V",
  BOX: "box",
  CELL: "cell",
};

export function anEmptySnyders() {
  let n = null;

  return [
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
    [n, n, n, n, n, n, n, n, n],
  ];
}

export function validateSnyder(snyder) {
  if (!snyder?.box || snyder?.box?.length > maxBox) return false;

  return !(!snyder?.cell || snyder?.cell?.length > maxCell);
}

export function handleSnyderClick(body, row, col, selectedNumber, cellMode) {

  if(!selectedNumber){
    return body;
  }

  if(!(cellMode === snyder_cell_mode.CELL || cellMode === snyder_cell_mode.BOX)){
    return body;
  }

  let updated = cloneDeep(body);
  defaultIfNull(updated, row, col);

  if (includes(updated.snyders[row][col][cellMode], selectedNumber)) {
    const index = updated.snyders[row][col][cellMode].indexOf(selectedNumber);
    updated.snyders[row][col][cellMode].splice(index, 1);
  } else {
    updated.snyders[row][col][cellMode] = union([selectedNumber], updated.snyders[row][col][cellMode]);
  }

  updated.snyders[row][col][cellMode].sort();

  if (validateSnyder(updated.snyders[row][col])) {
    return updated;
  }

  return body;
}

function defaultIfNull(body, row, col) {
  if (body.snyders[row][col] === null) {
    body.snyders[row][col] = {
      box: [],
      cell: [],
    };
  }
}
