import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InfoCard from './InfoCard';
import Layout from '../Layout';
import sudokuImg from '../../assets/sudoku.jpeg'
import amplifyImg from '../../assets/amplify.jpg';
import createImg from '../../assets/create.jpg';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  heading: {
    paddingTop: theme.spacing(2),
  }
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Layout>
      <Typography className={classes.heading} component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        Welcome
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
          <span role="img" aria-labelledby="guests">
          Feel free to play as the <Link href="#/about"> guest </Link>
          </span>
      </Typography>

      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          <Grid item key={1} xs={12} sm={6} md={4}>
            <InfoCard
              heading={'Sudoku'}
              description={'Play a game of Sudoku'}
              image={sudokuImg}
              link={'/sudoku'}
              dataTest={'home-sudoku-button'}
            />
          </Grid>
          <Grid item key={2} xs={12} sm={6} md={4}>
            <InfoCard
              heading={'Creator'}
              description={'Create your own Sudoku'}
              image={createImg}
              link={'/creator'}
              dataTest={'home-creator-button'}
            />
          </Grid>
          <Grid item key={3} xs={12} sm={6} md={4}>
            <InfoCard
              heading={'Data Management'}
              description={'Use this to reset your DynamoDB Items'}
              image={amplifyImg}
              link={'/data'}
              dataTest={'home-data-button'}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
