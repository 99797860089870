import React from 'react';
import Layout from '../Layout';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

export default function NotFound() {
  return (
    <Layout>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Oops
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          <span role="img" aria-labelledby="er, help">😢 er, help !</span>
        </Typography>
      </Container>
    </Layout>
  );
}