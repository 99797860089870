import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { snyder_cell_mode } from "./SnyderNotation";
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import BrushIcon from '@material-ui/icons/Brush';

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    margin: theme.spacing(1),
  },
  keyboard: {
    paddingTop: 10
  },
}));

export const buttons = {
  CLEAR_CELL: "x"
};

const KeyBoard = ({
                    onValueClick,
                    onCheckClick,
                    onSelectorOpenClick,
                    onResetClick,
                    onSnyderModeClick,
                    onUndoClick,
                    onColourClick,
                    selectedBackgroundColor
                  }) => {
  const classes = useStyles();

  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const aDataValue = (id) => {
    return `sudokuKeyBoardKey-${id}`
  }

  const handleKeyDown = (event) => {
    if (event.key === buttons.CLEAR_CELL || parseInt(event.key)) {
      const dataValue = aDataValue(event.key);
      document.querySelector(`[data-value="${dataValue}"]`).click();
    } else if (event.key === "Delete" || event.key === "Backspace") {
      onUndoClick()
    } else if (event.key === " ") {
      onColourClick()
    }
  };

  // todo why have the state in two place ?
  const handleSelectedValue = (event, newSelectedValue) => {
    setSelectedValue(newSelectedValue);
    onValueClick(newSelectedValue)
  };

  function aPrimaryButton(label, onClick) {
    return (
      <Button
        className={classes.primaryButton}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => onClick()}>
        {label}
      </Button>
    );
  }

  const aToggleNumberButton = (i) => {
    return (
      <ToggleButton
        key={i}
        data-value={aDataValue(i)}
        id="sudokuKeyBoardKey"
        value={i}>
        {i}
      </ToggleButton>)
  }

  const aToggleDeleteButton = () => {
    let i = buttons.CLEAR_CELL;
    return (
      <ToggleButton
        key={i}
        data-value={aDataValue(i)}
        id="sudokuKeyBoardKey"
        value={i}>
        <DeleteForeverOutlined style={{ fontSize: 20 }}/>
      </ToggleButton>)
  }

  const aColourButton = () => {
    const ColorButton = withStyles((theme) => ({
      root: {
        backgroundColor: selectedBackgroundColor,
      },
    }))(Button);

    return (
      <ColorButton
        className={classes.primaryButton}
        variant="contained"
        size="small"
        onClick={() => onColourClick()}>
        <BrushIcon style={{ fontSize: 20 }}/>
      </ColorButton>
    );
  }

  const toggleButtons = [];

  for (let i = 1; i <= 9; i++) {
    toggleButtons.push(aToggleNumberButton(i));
  }

  toggleButtons.push(aToggleDeleteButton());

  return (
    <div className={classes.keyboard}>
      <Grid container justify="center">
        <ToggleButtonGroup
          value={selectedValue}
          exclusive
          onChange={handleSelectedValue}
          aria-label="text SelectedValue">
          {toggleButtons}
        </ToggleButtonGroup>
      </Grid>

      <Grid container justify="center">
        <FormControl component="fieldset">
          <RadioGroup row
                      name="position"
                      defaultValue={snyder_cell_mode.SOLUTION}
                      onChange={(event) => onSnyderModeClick(event.target.value)}>
            <FormControlLabel
              value={snyder_cell_mode.BOX}
              control={<Radio color="primary"/>}
              label="Box"
              labelPlacement="end"
            />
            <FormControlLabel
              value={snyder_cell_mode.SOLUTION}
              control={<Radio color="primary"/>}
              label="Value"
              labelPlacement="end"
            />
            <FormControlLabel
              value={snyder_cell_mode.CELL}
              control={<Radio color="primary"/>}
              label="Cell"
              labelPlacement="end"
            />
            <FormControlLabel
              value={'colour'}
              control={<Radio color="primary"/>}
              label="Color"
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>

      </Grid>

      <Grid container justify="center">
        {aPrimaryButton("Undo", onUndoClick)}
        {aColourButton()}
        {aPrimaryButton("Check", onCheckClick)}
        {aPrimaryButton("Choose Puzzle", onSelectorOpenClick)}
        {aPrimaryButton("Reset", onResetClick)}
      </Grid>
    </div>
  );
};

KeyBoard.propTypes = {
  onValueClick: PropTypes.func.isRequired,
  onCheckClick: PropTypes.func.isRequired,
  onSelectorOpenClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  onColourClick: PropTypes.func.isRequired,
  selectedBackgroundColor: PropTypes.string,
  onSnyderModeClick: PropTypes.func.isRequired
};

export default KeyBoard;
