import React, { useEffect, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { cloneDeep } from "lodash";
import * as api from "./Api";
import "./Sudoku.css";
import Board, { mode } from "./Board";
import Layout from "../Layout";
import KeyBoard, { buttons } from "./KeyBoard";
import SolvedDialog from "./SolvedDialog";
import PuzzleSelector from "./PuzzleSelector";
import LoadingBackDrop from "./LoadingBackDrop";
import { anEmptySnyders, handleSnyderClick, snyder_cell_mode } from "./SnyderNotation";
import OkCancelDialog from "./OkCancelDialog";
import { useCookies } from 'react-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from "@material-ui/core/styles";
import { getColour, rotateColour, getFirstColour, getNoColour } from "./BackgroundImagesAndColours";

const useStyles = makeStyles(theme => ({
  heading: {
    paddingTop: theme.spacing(2),
  }
}));

const SudokuSolver = () => {
    const classes = useStyles();

    const [body, setBody] = useState(api.anEmptyBody());
    const [availablePuzzles, setAvailablePuzzles] = useState([]);
    const [cookies, setCookies] = useCookies(['puzzleId']); // todo move to cookies.js

    const [selectedNumber, setSelectedNumber] = useState();
    const [selectedBackgroundColor, setSelectedBackgroundColor] = useState(getFirstColour());
    const [cellMode, setCellMode] = useState(snyder_cell_mode.SOLUTION);
    const [undoHistory, setUndoHistory] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [solvedDialogOpen, setSolvedDialogOpen] = useState(false);
    const [puzzleSelectorOpen, setPuzzleSelectorOpen] = useState(false);
    const [okCancelDialogOpen, setOkCancelDialogOpen] = useState(false);

    const showDescription = useMediaQuery('(min-height:667px)');

    async function getFromAPI(idVal) {
      setIsLoading(true);

      const sudokuData = await api.getPuzzle(idVal)
      if (sudokuData) {
        setBody(sudokuData);
      }

      setIsLoading(false);

      return sudokuData;
    }

    function showPuzzleSelector() {
      api.getAvailable()
        .then((data) => {
          setAvailablePuzzles(data);
          setPuzzleSelectorOpen(true);
        })
    }

    function putToAPI(data) {
      api.putPuzzle(data);
    }

    // todo calls getFromAPI twice when switching puzzles
    useEffect(() => {
      if (body.puzzleId) {
        getFromAPI(body.puzzleId).then(console.log);
      } else if (cookies.puzzleId) {
        getFromAPI(cookies.puzzleId);
      } else {
        showPuzzleSelector();
      }

    }, [body.puzzleId, cookies.puzzleId]);


    function pushHistory() {
      setUndoHistory((prevState => {
        let updated = cloneDeep(prevState);
        updated.push(
          {
            snyders: body.snyders,
            cells: body.cells,
            backgroundColors: body.backgroundColors,
          }
        )
        return updated;
      }))
    }

    function popHistory() {
      if (undoHistory.length > 0) {
        setBody((previousBody) => {
          let updatedBody = cloneDeep(previousBody);

          let index = undoHistory.length - 1;

          updatedBody.cells = cloneDeep(undoHistory[index].cells);
          updatedBody.snyders = cloneDeep(undoHistory[index].snyders);
          updatedBody.backgroundColors = cloneDeep(undoHistory[index].backgroundColors);

          setUndoHistory(prevState => {
            prevState.pop();
            return prevState;
          });

          return updatedBody;
        });
      }
    }

    function toggleBackGroundColour(updatedBody, row, col) {

      if (updatedBody.backgroundColors[row][col] === selectedBackgroundColor) {
        updatedBody.backgroundColors[row][col] = getNoColour();
      } else {
        updatedBody.backgroundColors[row][col] = selectedBackgroundColor;
      }
    }

    function handleCellClick(row, col) {
      pushHistory();

      setBody((previousBody) => {
          let updatedBody = cloneDeep(previousBody);

          if (selectedNumber === buttons.CLEAR_CELL) {
            // delete was pressed
            updatedBody.cells[row][col] = null;
            updatedBody.snyders[row][col] = null;
            updatedBody.backgroundColors[row][col] = null;

          } else if (cellMode === "colour") {
            toggleBackGroundColour(updatedBody, row, col)
          } else {
            // values can be changed
            if (cellMode === snyder_cell_mode.SOLUTION) {
              if (updatedBody.cells[row][col] === selectedNumber) {
                updatedBody.cells[row][col] = null;
              } else {
                updatedBody.cells[row][col] = selectedNumber;
              }
            } else if (cellMode === snyder_cell_mode.CELL || cellMode === snyder_cell_mode.BOX) {
              updatedBody = handleSnyderClick(updatedBody, row, col, selectedNumber, cellMode);
            }
          }

          putToAPI(updatedBody)
          return updatedBody;
        }
      );
    }

    function handleGivenCellClick(row, col) {
      pushHistory();

      setBody((previousBody) => {
          let updatedBody = cloneDeep(previousBody);

          if (selectedNumber === buttons.CLEAR_CELL) {
            updatedBody.backgroundColors[row][col] = null;

          } else if (cellMode === "colour") {
            toggleBackGroundColour(updatedBody, row, col)
          } else {
            return updatedBody; // todo is this wrong ?
          }

          putToAPI(updatedBody)
          return updatedBody;
        }
      );
    }

    function handleBackgroundColorClick() {
      setSelectedBackgroundColor(rotateColour(selectedBackgroundColor))
    }

    function handleResetClick() {
      const cloneBody = cloneDeep(body);
      cloneBody.cells = cloneDeep(cloneBody.initialPuzzle);
      cloneBody.snyders = anEmptySnyders();
      cloneBody.backgroundColors = anEmptySnyders();
      setBody(cloneBody)
      setUndoHistory([])
      putToAPI(cloneBody);
    }

    function handlePuzzleIdChange(id) {
      setPuzzleSelectorOpen(false);

      let aWeekFromNow = new Date();
      aWeekFromNow.setTime(aWeekFromNow.getTime() + (7 * 86400 * 1000));
      setCookies("puzzleId", id, { path: "/", expires: aWeekFromNow }); // todo move to cookies.js
      setUndoHistory([])

      getFromAPI(id).then(console.log);
    }

    return (
      <Layout hideFooter={true}>
        <Container maxWidth="sm">
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography className={classes.heading} component="h4" variant="h4" align="center" color="textPrimary"
                        gutterBottom>
              {body.label ? body.label : 'Sudoku'}
            </Typography>

            {showDescription &&
            <Typography component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
              {body.description}
            </Typography>
            }

            <Board
              body={body}
              onClick={(row, col) => handleCellClick(row, col)}
              onGivenClick={(row, col) => handleGivenCellClick(row, col)}
              mode={mode.SOLVE}
            />

            <KeyBoard
              onValueClick={(value) => setSelectedNumber(value)}
              onCheckClick={() => setSolvedDialogOpen(true)}
              onSelectorOpenClick={() => showPuzzleSelector()}
              onResetClick={() => setOkCancelDialogOpen(true)}
              onSnyderModeClick={(value) => setCellMode(value)}
              onUndoClick={() => popHistory()}
              onColourClick={() => handleBackgroundColorClick()}
              selectedBackgroundColor={getColour(selectedBackgroundColor)}
            />

            <SolvedDialog
              open={solvedDialogOpen}
              onClose={() => setSolvedDialogOpen(false)}
              body={body}
            />

            <PuzzleSelector
              open={puzzleSelectorOpen}
              onChange={(value) => handlePuzzleIdChange(value)}
              availablePuzzles={availablePuzzles}
            />

            <LoadingBackDrop isLoading={isLoading}/>

            <OkCancelDialog
              open={okCancelDialogOpen}
              onOk={() => {
                setOkCancelDialogOpen(false);
                handleResetClick()
              }}
              onCancel={() => setOkCancelDialogOpen(false)}
              title={"You sure ?"}
              description={"There is no going back"}
            />
          </Grid>
        </Container>
      </Layout>
    );
  }
;

export default withAuthenticator(SudokuSolver);
