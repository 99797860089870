import React from "react";
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import { images } from "./BackgroundImagesAndColours";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  gridBorder: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  imageBorder: {
    border: "1px solid rgb(216, 216, 216)",
    margin: "1px",
  }
}));

const PuzzleSelector = ({ open, onChange }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <Container maxWidth="sm" className={classes.gridBorder}>
        <Grid container justify="center">
          {images.map((image, i) => (
            <div key={i} className={classes.imageBorder}>
              {/*TODO height*/}
              <img src={image} alt="" height="60" onClick={() => onChange(i)}/>
            </div>
          ))}
        </Grid>
        <Grid container justify="center" className={classes.gridBorder}>
          <Button
            // className={classes.primaryButton}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onChange(null)}>
            Cancel
          </Button>
        </Grid>
      </Container>
    </Dialog>
  );
}

PuzzleSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PuzzleSelector;
