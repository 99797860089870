import React from "react";
import PropTypes from 'prop-types';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import * as puzzleCells from "./Puzzle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

function SolvedDialog({ body, onClose, open }) {
  let errors = puzzleCells.isSolved(body);

  if (open && (errors.boxes.length === 0 && errors.rows.length === 0 && errors.cols.length === 0)) {

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle id="simple-dialog-title">Yeah, you've done it</DialogTitle>
      </Dialog>
    )
  } else {

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle id="simple-dialog-title">Hmm, have a look at</DialogTitle>
        <List>
          <ListItem><h4>{errors.boxes.length === 1 ? 'Box' : 'Boxes'} {errors.boxes.join(", ")} </h4></ListItem>
          <ListItem><h4>{errors.rows.length === 1 ? 'Row' : 'Rows'} {errors.rows.join(", ")} </h4></ListItem>
          <ListItem><h4>{errors.cols.length === 1 ? 'Column' : 'Columns'} {errors.cols.join(", ")} </h4></ListItem>
        </List>
      </Dialog>
    );
  }
}

SolvedDialog.propTypes = {
  body: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default SolvedDialog;
