/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "suduko",
            "endpoint": "https://sh1ed6r3s4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "suduko-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:5c17734e-8618-4f8e-8878-d5cbb778fff6",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_m6TqdaL0W",
    "aws_user_pools_web_client_id": "1dmctl5tq10l3ipvu0hmsguj5u",
    "oauth": {}
};


export default awsmobile;
